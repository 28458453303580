import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">
                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_16.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo16.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        全能无线连接器
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        全能无线连接器为您带来更安全、更快速的上网体验！
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qnwxljq/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="qnwxljq/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                </div>


                <div className="flex-justify-center box">
                <div className="flex-column content  flex-justify-center">
                    <img src={require("../assets/images/logo15.png")} alt="" width="118" height="118"/>
                    <div className="fs-40 mt-24">
                    智能WiFi秘书
                    </div>
                    <div className="fs-20 mt-24 bold-200 ">
                    智能WiFi秘书是您上网的好帮手，帮您解决网络安全问题、测试网速等都是一把好手。赶紧下载体验吧！
                    </div>
                    <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/znms/app.apk" target="_blank">
                        <div className="download-btn">
                            下载应用
                        </div>
                    </a>
                    <a href="znms/index.html" target="_blank">
                        <div className="detail-btn">
                            查看更多截图
                        </div>
                    </a>
                </div>
                <div>
                    <img src={require("../assets/images/phone_15.png")} alt="" width="324" height="514"/>
                </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_14.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo13.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        WiFi秒连伴侣钥匙
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        WiFi秒连伴侣钥匙让您轻松查看Wi-Fi信息，并且对网络情况进行检查，保护上网安全。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/mlbl_oppo/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="mlbl_oppo/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">

                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo13.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        WiFi秒连伴侣
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        WiFi秒连伴侣是一款强大的WiFi工具：可以帮您多角度检查WiFi情况，让您更了解您的WiFi；还有更多功能，下载试试吧！
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/mlbl/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="mlbl/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_13.png")} alt="" width="324" height="514"/>
                    </div>
               </div>


                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_12.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo12.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        鹰眼WiFi
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        鹰眼WiFi是一款专门针对网络连接和安全的工具应用。主要功能包括WiFi连接、网络安全检查、网络测速等，可以让您轻松管理和优化自己的网络使用体验。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/yy/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="yy/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">

                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo11.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        天天快清理手机管家
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        天天快清理手机管家，快乐清理每一天。一键解决手机垃圾，查杀病毒，清灰排水，为您的手机安全、空间、速度保驾护航
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/ttkqlsjgj_vivo/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="ttkqlsjgj_vivo/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_11.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_10.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo10.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        星秀炫酷来电秀(快应用)
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        好玩又新颖的动态壁纸，可以让喜欢的视觉形象出现在你的桌面上，粉丝们还可以自定义成自己的爱豆哦~
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/xxxkldx_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="xxkxldx_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看详情
                            </div>
                        </a>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        流星清理卫士
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        便捷的手机清理软件，有效地解决手机使用过程中卡顿、发热、内存不足的问题
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/lxqlws/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_1.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_2.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        星秀酷炫来电秀
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        让你的来电秀不限于单调的系统默认，热门潮流主题，轻松设置个性有趣的来电视频
                        </div>
                        {/*<a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/lxqlws/app.apk" target="_blank">*/}
                        {/*    <div className="download-btn">*/}
                        {/*        下载应用*/}
                        {/*    </div>*/}
                        {/*</a>*/}
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        简变P图
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        一键换背景，艺术滤镜，瞬间P掉路人！ 海量模板，让你一键生成证件照、节日贺卡、头像、表情包、海报、Vlog封面
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jbpt/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_3.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_8.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo8.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        立知天气
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        立知天气可以帮助您快捷查询天气状况，可快速查询全国天气、15日天气、还有天气预警、生活指数建议、日历等
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/lztq/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="lztq/index.html" target="_blank">
                            <div className="detail-btn">
                                查看详情
                            </div>
                        </a>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        万能魔音变声器
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        一款有趣、专业、个性化十足的变声器产品，适用于游戏、聊天、语音通话等多种场景，用一种全新的方式，让你的声音变得惟妙惟肖，改变声音从现在开始
                        </div>
                        {/*<a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/lztq/app.apk" target="_blank">*/}
                        {/*    <div className="download-btn">*/}
                        {/*        下载应用*/}
                        {/*    </div>*/}
                        {/*</a>*/}
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_5.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_6.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo6.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        巨牛内存管家
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        巨牛内存管家，智能清理手机，能够轻松清理手机中的各种垃圾，让您的手机感受飞一般的体验！一键清理，让手机速度迅速提升，彻底告别卡顿
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jnncgj/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo7.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        闪电文件管家
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        闪电文件管家，全面管理手机，解决手机各种垃圾及病毒问题，让手机彻底干净彻底安全！智能扫描，1秒钟让手机恢复运行速度
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/sdwj/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_7.png")} alt="" width="324" height="514"/>
                    </div>
               </div>



            </div>
        );
    }
export default Product;
