import React from 'react'
import '../assets/css/Footer.styl'
function Footer() {
        return (
            <div className="footer  flex-column flex-items-center flex-justify-center">
               <div className="fs-16">© 北京铂讯悦动科技有限公司 版权所有</div>
               <div className="fs-16 mt-16"><a className="mt-17" href='https://beian.miit.gov.cn/#/Integrated/recordQuery' target='_blank'>京ICP备2021011324号-1</a></div>
               <div className="wanganbeian-1">
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051054" className="wanganbeian-2"><img src={require("../assets/images/wanganbeian.png")} className="wanganbeian-3"/><p className="wanganbeian-4">京公网安备 11010502051054号</p></a>
		 	    </div>
            </div>
        );
    }
export default Footer;